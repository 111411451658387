import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountPage.css';
import { API_BASE_URL } from './config';
import Navbar from './Navbar';
import Footer from './Footer';
import { Alert, AlertDescription } from './ui/alert';
import { Button } from './ui/button';


const MyAccount = () => {
  const [activeTab, setActiveTab] = useState('details');
  const [bookings, setBookings] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [activeRegistrations, setActiveRegistrations] = useState([]);
  const [pastRegistrations, setPastRegistrations] = useState([]);
  const [userBookings, setUserBookings] = useState([]);
  const [cancelStatus, setCancelStatus] = useState({ show: false, message: '', isError: false });
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: '',
    phone: '',  // Add this line
  });
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [settings, setSettings] = useState({
    receiveNewsletters: false,
    deactivateAccount: false,
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user details on component mount

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserDetails(response.data);
      setError('');
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}/api/user/registrations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const currentDate = new Date();
        const active = [];
        const past = [];
  
        response.data.forEach((registration) => {
          const eventDate = new Date(registration.date);
          if (eventDate >= currentDate) {
            active.push(registration);
          } else {
            past.push(registration);
          }
        });
  
        setActiveRegistrations(active);
        setPastRegistrations(past);
        setRegistrations(response.data);
      } catch (err) {
        setRegistrations([]); // Ensure state is updated to reflect no data
      }
    };
  
    if (activeTab === 'registrations') {
      fetchRegistrations();
    }
  }, [activeTab]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/profile`,
        {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          phone: userDetails.phone,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess('Profile updated successfully');
      setError('');
      setActiveTab('details');
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    if (passwordData.newPassword.length < 6) {
      setError('Password must be at least 6 characters long');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/change-password`,
        {
          currentPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess('Password changed successfully');
      setError('');
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      setActiveTab('details');
    } catch (err) {
      if (err.response?.status === 400) {
        setError('Current password is incorrect');
      } else {
        handleSessionError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.delete(`${API_BASE_URL}/api/delete-account`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Account deleted successfully');
      logoutUser(); // Ensures cleanup and redirection
    } catch (err) {
      handleSessionError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      await axios.post(`${API_BASE_URL}/api/logout`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSuccess('Logged out successfully');
    } catch (err) {
      console.error('Logout failed, clearing session...');
    } finally {
      logoutUser();
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const handleSessionError = (err) => {
    if (err.response?.status === 401) {
      setError('Session expired. Please login again');
      logoutUser();
    } else {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };


  useEffect(() => {
    if (activeTab === 'bookings') {
      fetchBookings();
    }
  }, [activeTab]);

  const fetchBookings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/user/book-class`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBookings(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Failed to fetch bookings.');
    }
  };

  const handleCancelBooking = async (bookingId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_BASE_URL}/api/user/book-class/${bookingId}/cancel`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update local state to reflect cancellation
      setBookings(prevBookings =>
        prevBookings.map(booking =>
          booking._id === bookingId
            ? { ...booking, status: 'decline' }
            : booking
        )
      );

      setCancelStatus({
        show: true,
        message: 'Class booking cancelled successfully',
        isError: false
      });

      // Hide success message after 3 seconds
      setTimeout(() => {
        setCancelStatus({ show: false, message: '', isError: false });
      }, 3000);

    } catch (err) {
      setCancelStatus({
        show: true,
        message: err.response?.data?.message || 'Failed to cancel booking',
        isError: true
      });
    }
  };

  const activeBookings = bookings.filter(
    (booking) => booking.status === 'pending' || booking.status === 'approve'
  );

  const bookingHistory = bookings.filter(
    (booking) => booking.status === 'decline' || booking.status === 'complete'
  );

  const renderTable = (title, bookings) => (
    <div className="space-y-4">
      <h4 className="text-xl font-semibold">{title}</h4>
      {cancelStatus.show && (
        <Alert className={`mb-4 ${cancelStatus.isError ? 'bg-red-50' : 'bg-green-50'}`}>
          <AlertDescription>
            {cancelStatus.message}
          </AlertDescription>
        </Alert>
      )}
      {bookings.length > 0 ? (
        <div className="table-section">
          <table className="bookings-table">
            <thead>
              <tr className="bg-gray-50">
                <th className="p-3 text-left">Service</th>
                <th className="p-3 text-left">Instructor</th>
                <th className="p-3 text-left">Date</th>
                <th className="p-3 text-left">Time</th>
                <th className="p-3 text-left">Status</th>
                <th className="p-3 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking._id} className="border-b hover:bg-gray-50">
                  <td className="p-3">{booking.schedule.service.service_name}</td>
                  <td className="p-3">{booking.schedule.instructor.name}</td>
                  <td className="p-3">{new Date(booking.schedule.date).toLocaleDateString()}</td>
                  <td className="p-3">
                    {booking.schedule.start_time} - {booking.schedule.end_time}{' '}
                    {booking.schedule.timezone}
                  </td>
                  <td style={styles.td}>
                    <span className={`px-2 py-1 rounded-full text-sm ${
                      booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      booking.status === 'approve' ? 'bg-green-100 text-green-800' :
                      booking.status === 'decline' ? 'bg-red-100 text-red-800' :
                      booking.status === 'complete' ? 'bg-blue-100 text-blue-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {booking.status === 'pending' ? 'Awaiting Approval' :
                      booking.status === 'approve' ? 'Approved' :
                      booking.status === 'decline' ? 'Declined' :
                      booking.status === 'complete' ? 'Completed' :
                      booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                    </span>
                  </td>
                  <td className="p-3">
                    {booking.status === 'pending' && (
                      <Button 
                        variant="destructive"
                        size="sm"
                        onClick={() => handleCancelBooking(booking._id)}
                        className="cancel-button"
                      >
                        Cancel
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center text-gray-500 py-4">No bookings found in this category.</p>
      )}
    </div>
  );



useEffect(() => {
  const fetchUserBookings = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/api/user/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserBookings(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch bookings');
      setLoading(false);
    }
  };

  if (activeTab === 'userbookings') {
    fetchUserBookings();
  }
}, [activeTab]);

const handleCanceluserBooking = async (bookingId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(
      `${API_BASE_URL}/api/user/bookings/${bookingId}/cancel`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      // Update the local state to reflect the cancellation
      setUserBookings(prevBookings =>
        prevBookings.map(booking =>
          booking._id === bookingId
            ? { ...booking, status: 'declined' }
            : booking
        )
      );

      setCancelStatus({
        show: true,
        message: response.data.message || 'Booking cancelled successfully',
        isError: false
      });
    } else {
      throw new Error(response.data.message || 'Failed to cancel booking');
    }

    // Hide the success message after 3 seconds
    setTimeout(() => {
      setCancelStatus({ show: false, message: '', isError: false });
    }, 3000);

  } catch (err) {
    console.error('Cancel booking error:', err);
    setCancelStatus({
      show: true,
      message: err.response?.data?.message || err.message || 'Failed to cancel booking',
      isError: true
    });
  }
};

if (loading) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: '#555' }}>Loading...</p>;
if (error) return <p style={{ textAlign: 'center', fontSize: '1.2rem', color: 'red' }}>Error: {error}</p>;

const activeUserBookings = userBookings.filter(
  (booking) => booking.status === 'pending' || booking.status === 'approved'
);
const bookingUserHistory = userBookings.filter(
  (booking) => booking.status === 'declined' || booking.status === 'completed'
);

const renderUserTable = (title, bookings) => (
  <div className="mb-8">
    <h4 style={styles.subHeader}>{title}</h4>
    {cancelStatus.show && (
      <Alert className={`mb-4 ${cancelStatus.isError ? 'bg-red-50' : 'bg-green-50'}`}>
        <AlertDescription>
          {cancelStatus.message}
        </AlertDescription>
      </Alert>
    )}
    {bookings.length > 0 ? (
      <div className="table-section">
        <table className="bookings-table">
          <thead>
            <tr className="bg-gray-50">
              <th style={styles.th}>Service</th>
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Time</th>
              <th style={styles.th}>Status</th>
              <th style={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking._id} className="border-b hover:bg-gray-50">
                <td style={styles.td}>{booking.service.service_name}</td>
                <td style={styles.td}>{new Date(booking.date).toLocaleDateString()}</td>
                <td style={styles.td}>{booking.time}</td>
                <td style={styles.td}>
              <span className={`px-2 py-1 rounded-full text-sm ${
                booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                booking.status === 'approved' ? 'bg-green-100 text-green-800' :
                booking.status === 'declined' ? 'bg-red-100 text-red-800' :
                booking.status === 'compeleted' ? 'bg-blue-100 text-blue-800' :
                'bg-gray-100 text-gray-800'
              }`}>
                {booking.status === 'pending' ? 'Awaiting Approval' :
                booking.status === 'approved' ? 'Approved' :
                booking.status === 'declined' ? 'Declined' :
                booking.status === 'completed' ? 'Completed' :
                booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
              </span>
            </td>
                <td className="p-3">
                  {booking.status === 'pending' && (
                    <button 
                      variant="destructive"
                      size="sm"
                      onClick={() => handleCanceluserBooking(booking._id)}
                      className="cancel-button"
                      
                    >
                      Cancel
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p className="text-center text-gray-500 py-4">No bookings available in this category.</p>
    )}
  </div>
);



  return (
    <>
    <Navbar />
    
    <div className="account-container">
      
      {/* Sidebar Navigation */}
      <div className="sidebar">
        <h2>My Account</h2>
        <ul>
          <li 
            className={activeTab === 'details' ? 'active' : ''} 
            onClick={() => setActiveTab('details')}
          >
            My details
          </li>
          <li 
            className={activeTab === 'edit' ? 'active' : ''} 
            onClick={() => setActiveTab('edit')}
          >
            Edit Profile
          </li>
          <li
            className={activeTab === 'Classes Bookings' ? 'active' : ''}
            onClick={() => setActiveTab('Classes Bookings')}
          >
           Classes Bookings 
          </li>
          <li
            className={activeTab === 'Schedule Bookings' ? 'active' : ''}
            onClick={() => setActiveTab('Schedule Bookings')}
          >
           Schedule Bookings 
          </li>
          <li
            className={activeTab === 'registrations' ? 'active' : ''}
            onClick={() => setActiveTab('registrations')}
          >
           Event Registrations 
          </li>
          <li 
            className={activeTab === 'password' ? 'active' : ''} 
            onClick={() => setActiveTab('password')}
          >
            Change Password
          </li>
          <li 
            className={activeTab === 'settings' ? 'active' : ''} 
            onClick={() => setActiveTab('settings')}
          >
            Account settings
          </li>
        </ul>
      </div>

      {/* Main Content Area */}
      <div className="details-container">
        {/* Error and Success Messages */}
        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}
        {loading && <div className="loading">Loading...</div>}

        {/* Breadcrumbs */}
        <div className="breadcrumbs">
          Homepage / My Account {activeTab !== 'details' && `/ ${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}`}
        </div>

        {/* Content Sections */}
        {activeTab === 'details' && (
  <>
    <h2>My details</h2>
    <div className="form-section">
      <h3>Personal Information</h3>
      <p>View and manage your personal information.</p>
      <form>
        <div className="form-group">
          <label>First Name</label>
          <input type="text" value={userDetails.firstName} disabled />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input type="text" value={userDetails.lastName} disabled />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input type="tel" value={userDetails.phone} disabled />
        </div>
      </form>
    </div>
    <div className="form-section">
      <h3>E-mail address</h3>
      <p>Assertively utilize adaptive customer service for future-proof platforms. Completely drive optimal markets.</p>
      <div className="form-group">
        <label>E-mail address</label>
        <input type="email" value={userDetails.email} placeholder="email@example.pl" disabled />
      </div>
    </div>
  </>
)}

{activeTab === 'edit' && (
  <>
    <h2>Edit Profile</h2>
    <form onSubmit={handleUpdateProfile}>
      <div className="form-group">
        <label>First Name</label>
        <input
          type="text"
          value={userDetails.firstName}
          onChange={(e) => setUserDetails({...userDetails, firstName: e.target.value})}
          required
        />
      </div>
      <div className="form-group">
        <label>Last Name</label>
        <input
          type="text"
          value={userDetails.lastName}
          onChange={(e) => setUserDetails({...userDetails, lastName: e.target.value})}
          required
        />
      </div>
      <div className="form-group">
      <label>Phone Number</label>
      <input
        type="tel"
        value={userDetails.phone}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '').slice(0, 15);
          setUserDetails({...userDetails, phone: value});
        }}
        pattern="[0-9]{6,15}"
        placeholder="Enter phone number"
        minLength="6"
        maxLength="15"
        required
      />
    </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          value={userDetails.email}
          onChange={(e) => setUserDetails({...userDetails, email: e.target.value})}
          required
        />
      </div>
      <button className="save-button" type="submit" disabled={loading}>
        {loading ? 'Saving...' : 'Save Changes'}
      </button>
    </form>
  </>
)}

        {activeTab === 'password' && (
          <>
            <h2>Change Password</h2>
            <form onSubmit={handleChangePassword}>
              <div className="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  value={passwordData.currentPassword}
                  onChange={(e) => setPasswordData({...passwordData, currentPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  value={passwordData.newPassword}
                  onChange={(e) => setPasswordData({...passwordData, newPassword: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  value={passwordData.confirmPassword}
                  onChange={(e) => setPasswordData({...passwordData, confirmPassword: e.target.value})}
                  required
                />
              </div>
              <button className="save-button" type="submit" disabled={loading}>
                {loading ? 'Changing Password...' : 'Change Password'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'settings' && (
          <>
            <h2>Account Settings</h2>
            <form>
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={settings.receiveNewsletters}
                    onChange={(e) => setSettings({...settings, receiveNewsletters: e.target.checked})}
                  />
                  Receive Newsletters
                </label>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="logout-button"
                  onClick={handleLogout}
                  disabled={loading}
                >
                  Logout
                </button>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="delete-account-button"
                  onClick={handleDeleteAccount}
                  disabled={loading}
                >
                  Delete Account
                </button>
              </div>
            </form>
          </>
        )}

        {activeTab === 'Schedule Bookings' && (
              <div className="bookings-container">
              {error ? (
                <p className="error">{error}</p>
              ) : (
                <>
                  {renderTable('Active Bookings', activeBookings)}
                  {renderTable('Booking History', bookingHistory)}
                </>
              )}
            </div>
   
          

        )}

{activeTab === 'Classes Bookings' && (
        <div style={styles.container}>
        <h2 style={styles.header}>Your Bookings</h2>
        {renderUserTable('Active Bookings', activeUserBookings)}
        {renderUserTable('Booking History', bookingUserHistory)}
    </div>
   
          

        )}

{activeTab === 'registrations' && (
  <div className="table-container">
    <h1>Your Event Registrations</h1>

    <section>
      <h3>Active Registrations</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {activeRegistrations.length > 0 ? (
            activeRegistrations.map((registration) => (
              <tr key={registration._id}>
                <td data-label="Event Name">{registration.event.title}</td>
                <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                <td data-label="Time">{registration.time}</td>
                <td data-label="Location">{registration.location}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>No active registrations found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </section>

    <section>
      <h3>Past Registrations</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {pastRegistrations.length > 0 ? (
            pastRegistrations.map((registration) => (
              <tr key={registration._id}>
                <td data-label="Event Name">{registration.event.title}</td>
                <td data-label="Date">{new Date(registration.date).toLocaleDateString()}</td>
                <td data-label="Time">{registration.time}</td>
                <td data-label="Location">{registration.location}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>No past registrations found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  </div>
)}
      </div>
    </div>
    <Footer />
    </>
  );
};

const styles = {
  container: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '1200px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 768px)': {
      margin: '10px',
      padding: '15px',
      borderRadius: '6px',
    },
    '@media (max-width: 480px)': {
      margin: '5px',
      padding: '10px',
    }
  },
  header: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#333',
    '@media (max-width: 768px)': {
      fontSize: '1.3rem',
      marginBottom: '15px',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.2rem',
      marginBottom: '12px',
    }
  },
  subHeader: {
    fontSize: '1rem',
    margin: '20px 0',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      margin: '15px 0',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.85rem',
      margin: '12px 0',
    }
  },
  
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      display: 'block',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }
  },
  tableWrapper: {
    '@media (max-width: 768px)': {
      overflowX: 'auto',
      WebkitOverflowScrolling: 'touch',
      marginBottom: '15px',
    }
  },
  th: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    '@media (max-width: 768px)': {
      padding: '8px',
      fontSize: '0.9rem',
      minWidth: '120px', // Ensures columns don't get too narrow
    },
    '@media (max-width: 480px)': {
      padding: '6px',
      fontSize: '0.85rem',
    }
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    color: '#555',
    '@media (max-width: 768px)': {
      padding: '8px',
      fontSize: '0.9rem',
      minWidth: '120px',
    },
    '@media (max-width: 480px)': {
      padding: '6px',
      fontSize: '0.85rem',
    }
  },
  row: {
    backgroundColor: '#fff',
    '@media (max-width: 768px)': {
      '&:hover': {
        backgroundColor: '#f5f5f5',
      }
    }
  },
  noData: {
    textAlign: 'center',
    color: '#777',
    fontStyle: 'italic',
    '@media (max-width: 768px)': {
      fontSize: '0.9rem',
      padding: '15px 0',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.85rem',
      padding: '12px 0',
    }
  },
  // Optional: Add styles for mobile-specific table view
  mobileTableCell: {
    '@media (max-width: 480px)': {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      borderBottom: '1px solid #ddd',
    }
  },
  mobileLabel: {
    '@media (max-width: 480px)': {
      fontWeight: 'bold',
      marginBottom: '4px',
      color: '#333',
    }
  }
};

export default MyAccount;