import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'
import './Price.css'
import Header from './Header';
import { getPricingPackages } from './servicesapi';

const Price = () => {
    const [pricingData, setPricingData] = useState({
        Trial: [],
        'Monthly Pass': [],
        'Specialty Classes': [],
        'Private Classes': []
      });
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
    
      useEffect(() => {
        const fetchPricing = async () => {
          try {
            setLoading(true);
            const data = await getPricingPackages();
            setPricingData(data);
          } catch (err) {
            setError('Failed to load pricing information. Please try again later.');
            console.error(err);
          } finally {
            setLoading(false);
          }
        };
    
        fetchPricing();
      }, []);
    
      const renderPricingItem = (item) => {
        // Modify the condition to be more explicit
        const isSingleDropIn = item.name === 'Single drop-in';
        const itemClassName = isSingleDropIn 
          ? 'price-item321 single-drop-in' 
          : 'price-item321';
    
        if (item.discountedPrice) {
          return (
            <div 
              className={itemClassName} 
              key={item._id} 
              style={isSingleDropIn ? { 
                borderTop: '1px solid #000000', 
                borderBottom: '1px solid #000000',
                paddingTop: '15px',
                paddingBottom: '15px',
                marginTop: '10px',
                marginBottom: '10px' 
              } : {}}
            >
              <span>{item.name} {item.note && <span className="note-month">{item.note}</span>}</span>
              <span className='or-dis-price'>
                <span className="original-price321">{item.priceUnit}{item.originalPrice}</span> 
                <span className="discounted-price321">{item.priceUnit}{item.discountedPrice}</span>
              </span>
            </div>
          );
        } else if (item.originalPrice === 0) {
          return (
            <div 
              className={itemClassName} 
              key={item._id}
              style={isSingleDropIn ? { 
                borderTop: '1px solid #000000', 
                borderBottom: '1px solid #000000',
                paddingTop: '15px',
                paddingBottom: '15px',
                marginTop: '10px',
                marginBottom: '10px',
                
              } : {}}
            >
              <span className='coprate'>{item.name} {item.note && <span className="note-month"></span>}</span>
              <span className="original-price-co">{item.note || 'Contact our team to arrange'}</span>
            </div>
          );
        } else {
          return (
            <div 
              className={itemClassName} 
              key={item._id}
              style={isSingleDropIn ? { 
                borderTop: '1px solid #000000', 
                borderBottom: '1px solid #000000',
                paddingTop: '30px',
                paddingBottom: '30px',
                marginTop: '50px',
                marginBottom: '10px' 
              } : {}}
            >
              <span className={item.name.includes('Private') ? 'Private-1-on-1' : item.name.includes('pass') || item.name.includes('Unlimited') ? 'monthly-pass' : ''}>
                {item.name} {item.note && <span className="note-month">{item.note}</span>}
              </span>
              <span className="original-price321">
                {item.priceUnit}{item.originalPrice} {item.priceNote && <span>{item.priceNote}</span>}
              </span>
            </div>
          );
        }
      };
    
      if (loading) {
        return <div className="loading">Loading pricing information...</div>;
      }
    
      if (error) {
        return <div className="error">{error}</div>;
      }  
  return (
    <div>
        <Navbar/>
        <div className="hero-wildthing">
        <div className="hero-text-wildthing">
          <h1>Pricing & Packages</h1>
          <div className="logo-wildthing">
            <img src="images/background-2.svg" alt="Logo"/>
        </div>
        </div>
      </div>
      <div className="container-wrapper321 ">
        <div className="container321 card123">
          {/* Trial section */}
          <div className="section321" style={{ border: 'none' }}>
            <h2 className="title123">NEW! Trials and offers</h2>
            {pricingData.Trial && pricingData.Trial.map(renderPricingItem)}
          </div>

          {/* Monthly Pass section */}
          <div className="section321">
            <h2 className="title123">Monthly Pass</h2>
            {pricingData['Monthly Pass'] && pricingData['Monthly Pass'].map(renderPricingItem)}
          </div>
          <div className='divider-first'></div>

          {/* Specialty Classes section */}
          <div className="section321">
            <h2 className="title123">Specialty Classes</h2>
            {pricingData['Specialty Classes'] && pricingData['Specialty Classes'].map(renderPricingItem)}
          </div>
          <div className='divider-first'></div>

          {/* Private Classes section */}
          <div className="section321" style={{ border: 'none' }}>
            <h2 className="title123">Private Classes</h2>
            {pricingData['Private Classes'] && pricingData['Private Classes'].map(renderPricingItem)}
          </div>
                <div className='divider-first'></div>
               
               <div class="section321">
               <div class="payment-section">
                    <div class="payment-header">
                        <h2 style={{margin: '0'}}>Payment Methods</h2>
                        <p style={{margin: "0.5rem 0 0 0"}}>Payment should be made in advance to secure your spot in the class. Here are the payment methods that you can use:</p>
                    </div>

                    <ul class="payment-methods">
                        <li class="payment-method">
                            <div class="payment-number">1</div>
                            <div>FPS to 96832177</div>
                        </li>
                        <li class="payment-method">
                            <div class="payment-number">2</div>
                            <div>PayMe to 96832177 or via the following link: <a href="https://payme.hsbc/georgialouise.yoga" style={{color:" #2c5282;"}}>payme.hsbc/georgialouise.yoga</a></div>
                        </li>
                        <li class="payment-method">
                            <div class="payment-number">3</div>
                            <div>Cash</div>
                        </li>
                    </ul>

                    <div class="whatsapp-confirmation">
                        Once payment is made you can message 96832177 via WhatsApp to confirm.
                    </div>
                </div>
               </div>
            </div>
    
            <div class="container567">
                
                <h2>Important Information</h2>
                <p>All our yoga classes are conducted in <span class="highlight321">English</span>.</p>
                <p>Class bookings and session updates are <span class="highlight321">conducted through our WhatsApp group</span> - whether you’re a new member of our community, or a returning yogi, please contact us to join.</p>
                <p><span class="highlight-blue321">Class location subject to change - </span> <br/>the most updated information can be found <br/>in our WhatsApp group, please contact us to join.</p>
                <a href="https://chat.whatsapp.com/IfJcbh0ike8Iqjh62nblhD" class="button321">Join us on WhatsApp <img src="images/WhatsApp.svg" alt="WhatsApp Icon" width="20"/></a>
                <div className="decorative-card-four-right">
                <img src='images/background-2.svg' alt="Decorative element" />
            </div>
                  
            </div>
           
        </div>
        <Header />
      
    </div>
  )
}

export default Price
