// File: src/services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://ekamvida.infoorangetech.com/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getPricingPackages = async () => {
  try {
    const response = await api.get('/pricing');
    return response.data;
  } catch (error) {
    console.error('Error fetching pricing packages:', error);
    throw error;
  }
};

export const getPricingByCategory = async (category) => {
  try {
    const response = await api.get(`/pricing/category/${encodeURIComponent(category)}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${category} pricing:`, error);
    throw error;
  }
};

export default api;